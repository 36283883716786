header {
    position: fixed;
    top: 0;
    left: 0;
    padding-block: 10px;
    background-color: $color-4;
    z-index: 20;
    width: 100%;
    font-size: rem(16);
    -webkit-box-shadow: 0px 9px 13px 0px rgba(66, 68, 90, 0.25);
    -moz-box-shadow: 0px 9px 13px 0px rgba(66, 68, 90, 0.25);
    box-shadow: 0px 9px 13px 0px rgba(66, 68, 90, 0.25);
    .navigation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        &__list {
            display: flex;
            align-items: center;
            gap: 20px;
            transition: 0.5s;
            @media (max-width: $lg) {
                position: absolute;
                flex-direction: column;
                justify-content: flex-start;
                padding-top: 40%;
                gap: 30px;
                width: 100%;
                height: 100vh;
                background-color: $color-4;
                top: 95px;
                left: 0;
                transform: translateY(-100%);
            }
            &--active {
                transform: translateY(0);
            }
            &__submenu {
                cursor: pointer;
                position: relative;
                width: auto;
                display: inline;

                &__span {
                    width: 100%;
                    text-align: center;

                    display: block;
                    &:after {
                        content: "";
                        width: 15px;
                        height: 15px;
                        display: inline-block;

                        transform: translateY(2px) rotate(90deg);
                        transition: 0.3s;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath d='M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z'/%3E%3C/svg%3E");
                        background-size: contain;
                        @media (max-width: $lg) {
                            position: absolute;
                            right: calc(50% - 50px);
                            top: 0;
                        }
                    }
                }
                &__span--active:after {
                    transform: rotate(270deg);
                }
            }

            &__submenu--active:after {
                transform: rotate(270deg);
            }

            li {
                position: relative;
            }
        }
        &__logo {
            max-width: 100px;
            position: relative;
            z-index: 50;
            img {
                width: 100%;
            }
        }
    }
}

.submenu {
    position: absolute;
    top: 40px;
    right: -30px;
    transition: 0.5s;
    width: 250px;
    max-height: 0;
    overflow: hidden;
    z-index: -1;
    transform: translateY(-30px);
    opacity: 0;
    -webkit-box-shadow: 0px 9px 13px 0px rgba(66, 68, 90, 0.25);
    -moz-box-shadow: 0px 9px 13px 0px rgba(66, 68, 90, 0.25);
    box-shadow: 0px 9px 13px 0px rgba(66, 68, 90, 0.25);
    border-radius: 20px;
    @media (max-width: $lg) {
        position: static;
        box-shadow: none;
    }
    &__active {
        height: auto;
        transition: 0.5s;
        transform: translateY(0);
        opacity: 1;
        max-height: 300px;
    }
    &__list {
        background-color: $color-4;
        padding-inline: 50px;
        padding-block: 50px;

        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        @media (max-width: $lg) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-block: 25px;
        }
    }
}
