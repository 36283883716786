.hero {
  width: 100%;
  height: 100vh;
  min-height: 400px;
  max-height: 700px;
  position: relative;
  flex-direction: column;
  row-gap: 30px;
  text-align: center;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-position: center;
    object-fit: cover;
  }
  &__button-wrapper {
    display: flex;
    gap: rem(20);
    @media (max-width: $md) {
      flex-direction: column;
    }
  }
}
