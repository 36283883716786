footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 20px;
  background-color: $color-6;
  color: $color-4;
  padding-inline: rem(15);
  .footer {
    &__cols {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media (max-width: $md) {
        flex-direction: column;
        align-items: center;
        gap: rem(30);
        padding-block: rem(10);
      }
    }
    &__col {
      width: 33%;
      text-align: center;
      font-size: rem(16);
      @media (max-width: $md) {
        width: 100%;
      }
      &:nth-child(1) {
        @media (max-width: $md) {
          display: none;
        }
      }
      &:nth-child(3) {
        font-size: rem(14);
        font-weight: 400;
      }
    }
  }
}
