@import "../fonts/fonts";
@import "global/variables.scss";
@import "global/reset.scss";
@import "global/global.scss";

@import "components/page-title";
@import "components/btn";
@import "components/footer";
@import "components/hamburger";
@import "components/hero-comp";
@import "components/_items";
@import "components/_filters";
@import "components/_socials";
@import "components/_grid";
@import "section/about-hero";
@import "section/contact-section";
@import "section/contact";
@import "section/four-cols";
@import "section/header";
@import "section/hero";
@import "section/two-cols";
@import "section/cta-leaf";
@import "section/services";
@import "section/text";
@import "section/short";
