/*Colors*/
$color-1: #9acd32;
$color-2: #698b22;
$color-3: #f3b32c;
$color-4: #fff;
$color-5: #000;
$color-6: #505050;

/*Breakpoints*/
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

/*Fonts*/
$primary-font: "Montserrat", sans-serif;
$secondary-font: "Barlow Condensed", sans-serif;

@function rem($sizeValue: 16) {
  @return calc($sizeValue / 16) + rem;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
