.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  max-width: rem(700);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > &__element:nth-child(1) {
    grid-area: 1 / 1 / 4 / 4;
  }
  > &__element:nth-child(2) {
    grid-area: 1 / 4 / 3 / 6;
  }
  > &__element:nth-child(4) {
    grid-area: 3 / 4 / 6 / 6;
  }
  > &__element:nth-child(3) {
    grid-area: 4 / 1 / 6 / 4;
    background-color: $color-1;
    color: $color-4;
    font-family: $secondary-font;
    font-size: rem(20);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: background-color 0.3s ease-in-out;
    @media (max-width: $md) {
      font-size: rem(15);
    }
    &:hover {
      background-color: $color-6;
    }
  }
}
