.menu-btn-1 {
    height: 32px;
    width: 40px;
    cursor: pointer;
    display: none;
    @media (max-width: $lg) {
        display: block;
    }
}

.menu-btn-1 span,
.menu-btn-1 span::before,
.menu-btn-1 span::after {
    background: $color-2;
    border-radius: 3px;
    content: "";
    position: absolute;
    width: 40px;
    height: 6px;
    margin-top: 13px;

    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.menu-btn-1 span::before {
    margin-top: -12px;
}

.menu-btn-1 span::after {
    margin-top: 12px;
}

.menu-btn-1.active span {
    background: transparent;
}

.menu-btn-1.active span::before {
    margin-top: 0;

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.menu-btn-1.active span::after {
    margin-top: 0;

    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
