.text {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-inline: 100px;
    @media (max-width: $lg) {
        padding-inline: 25px;
    }
    @media (max-width: $md) {
        padding-inline: 15px;
    }
    h2 {
        margin-bottom: 50px;
        @media (max-width: $lg) {
            margin-bottom: 30px;
        }
    }
    span {
        margin-bottom: 25px;
        @media (max-width: $lg) {
            margin-bottom: 15px;
        }
    }
    p {
        margin-bottom: 30px;
        text-align: left;
        @media (max-width: $lg) {
            margin-bottom: 15px;
        }
    }
    &__img {
        width: 100%;
        img {
            width: 100%;
            clip-path: polygon(0 0, 100% 30%, 100% 100%, 0 100%);
        }
    }
    button {
        margin-inline: auto;
        font-weight: bold;
        padding-block: 20px;
        border-radius: 50px;
        margin-top: 50px;
    }
    ul,
    ol {
        padding-left: 50px;
        margin-bottom: 50px;
        li {
            margin-bottom: 5px;
        }
        @media (max-width: $lg) {
            margin-bottom: 30px;
            padding-left: 25px;
        }
    }
    ul {
        list-style: disc;
    }
}
