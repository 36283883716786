.socials {
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        transition: 0.3s;
        color: $color-1;
    }
    &:hover svg path {
        fill: $color-2;
    }
    &:hover {
        color: $color-2;
    }
    svg {
        width: 32px;
    }
}
