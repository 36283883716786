* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: var(--header-height);
  -webkit-text-size-adjust: 100%;
}
body {
  font-size: clamp(rem(14), 2vw, rem(18));
  line-height: 1.2;
  font-family: $primary-font;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  @media (max-width: $lg) {
    min-height: auto;
  }
}
img {
  max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $secondary-font;
}
h2,
h3,
h4,
h5,
h6 {
  color: $color-2;
}
h1 {
  font-size: rem(70);
  text-transform: uppercase;
  font-weight: bold;
  color: $color-4;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: $md) {
    font-size: rem(32);
  }
}

h2 {
  font-size: clamp(rem(20), 5vw, rem(50));
  margin-bottom: rem(50);
  font-weight: bold;
  text-transform: uppercase;
  color: $color-6;
  @media (max-width: $md) {
    margin-bottom: rem(20);
  }
}
h3 {
  font-size: clamp(rem(18), 3vw, rem(24));
}
a {
  text-decoration: none;
  color: inherit;
}

section {
  margin-block: rem(100);
  @media (max-width: $md) {
    margin-block: 55px;
  }
}

.container {
  width: min(100%, 1225px);
  margin-inline: auto;
  padding-inline: 25px;
  @media (max-width: $md) {
    padding-inline: 15px;
  }
}
