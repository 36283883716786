.two-cols {
  @media (max-width: $lg) {
    margin-top: 0px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    overflow: hidden;
    @media (max-width: $lg) {
      flex-direction: column-reverse;
      gap: 50px;
      margin-top: 0px;
    }
  }
  &__text {
    width: 50%;
    @media (max-width: $lg) {
      width: 100%;
    }

    p {
      margin-block: 20px;
    }
  }
}
