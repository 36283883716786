.page-title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    h1 {
        color: $color-1;
        margin-left: -180px;
        font-weight: bold;
        text-shadow: none;
        @media (max-width: $xl) {
            margin-left: -120px;
        }
        @media (max-width: $md) {
            margin-left: -50px;
        }
    }
    img {
        transform: translateX(-70px);
        @media (max-width: $xl) {
            transform: translateX(0px);
        }
        @media (max-width: $md) {
            max-width: 150px;
        }
    }
}

.long-headline {
    font-size: clamp(1.3rem, 5vw, 4rem);
}
