.filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  column-gap: 30px;
  row-gap: 15px;
  @media (max-width: $sm) {
    flex-direction: column;
  }
}

.filters a.active {
  font-weight: bold;
}
