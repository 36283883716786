.cta-leaf {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  padding-block: 0;
  gap: rem(15);
  @media (max-width: $md) {
    flex-direction: column;
    padding-block: rem(20);
    gap: rem(50);
  }

  &:before {
    content: "";
    width: 100%;
    height: 80%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: $color-1;
    z-index: -1;
    @media (max-width: $md) {
      height: 100%;
    }
  }

  &__text {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    color: $color-4;
    h2 {
      margin-bottom: 0;
      color: $color-4;
    }
    p {
      margin-bottom: rem(10);
    }
  }
}
