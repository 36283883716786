.items {
  width: 100%;

  strong {
    font-weight: bold;
  }

  &__wrapper {
    display: grid;
    gap: rem(30);
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: $md) {
      grid-template-columns: 1fr;
    }
  }

  &__bilboard {
    margin-inline: auto;
    margin-bottom: rem(50);
    width: 50%;

    @media (max-width: $lg) {
      width: 100%;
    }
  }

  &__item {
    border: 2px solid $color-1;
    color: $color-4;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    overflow: hidden;
    transition: 0.5s;
    width: 100%;
    box-shadow: 5px 5px 5px 0px rgba(66, 68, 90, 0.25);

    @media (max-width: $xl) {
      flex-direction: column-reverse;
    }

    &__left {
      background-color: $color-1;
      flex: 1;
      padding: rem(50);
      width: 100%;

      @media (max-width: $xl) {
        width: 100%;
      }

      @media (max-width: $md) {
        padding: rem(25);
      }

      h3 {
        color: $color-4;
      }

      p {
        margin-block: rem(30);
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: rem(10);
      }
    }

    &__price {
      margin-top: rem(30);

      strong {
        font-weight: 600;
        margin-left: rem(5);
      }
    }

    &__right {
      align-items: center;
      color: $color-2;
      display: flex;
      flex-direction: column;
      gap: rem(50);
      height: rem(400);
      justify-content: space-between;
      padding-block: rem(40);
      padding-inline: rem(30);
      width: 100%;

      @media (max-width: $xl) {
        width: 100%;
      }

      @media (max-width: $md) {
        gap: rem(25);
        height: rem(250);
        padding: rem(25);
      }

      p {
        text-align: center;
      }

      img {
        height: 80%;
        object-fit: contain;
        width: 100%;
      }
    }
  }
}
