.btn-primary {
  background-color: $color-1;
  width: auto;
  padding: 15px 35px;
  border: 2px solid transparent;
  color: $color-4;
  font-size: rem(20);
  font-weight: bold;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  font-family: $primary-font;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: inline-block;

  &:hover {
    background-color: $color-4;
    color: $color-1;
    border-color: $color-1;
  }
  &--outline {
    background-color: $color-4;
    color: $color-1;

    &:hover {
      background-color: $color-1;
      color: $color-4;
      border-color: $color-4;
    }
  }
}
