.contact {
  display: flex;
  align-items: stretch;
  padding-bottom: 0;
  margin-inline: rem(15);

  @media (max-width: $lg) {
    flex-direction: column;
  }
  &__container {
    border-radius: rem(50);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    color: $color-4;
    padding: rem(40) rem(60);
    @media (max-width: $md) {
      flex-direction: column;
      padding-inline: rem(15);
      text-align: center;
      gap: rem(20);
    }
  }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 0.9;
  }
  &__column {
    width: 50%;
    @media (max-width: $md) {
      width: 100%;
    }
    p {
      margin-bottom: rem(20);
    }
  }
  h2 {
    color: $color-1;
    margin-bottom: 20px;
  }
  a {
    text-decoration: none;
    color: $color-4;
  }

  &__text {
    background-color: $color-1;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 50px;
    padding-right: 150px;
    gap: 50px;
    position: relative;
    z-index: 2;
    color: $color-1;
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: 1250px) {
      padding-right: 25px;
    }
    @media (max-width: $lg) {
      width: 100%;
      padding-inline: 0;
      justify-content: center;
      align-items: center;
    }

    img {
      filter: brightness(0) invert(1);
      width: 70%;
    }
    &__icon__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
      @media (max-width: $md) {
        justify-content: center;
        align-items: center;
      }
    }
    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;
    }
  }
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: rem(200);
    gap: rem(30);
    padding-inline: rem(30);
    padding-block: rem(5);
    transition: 0.3s ease;
    border-radius: 15px;
    color: $color-1;
    @media (max-width: $md) {
      gap: rem(15);
    }
    &:hover p {
      color: $color-1;
    }

    @media (max-width: $md) {
      padding-inline: 0;
      flex-direction: column;
      width: 100%;
      min-width: none;
    }
    p {
      margin-bottom: 0;
      font-weight: bold;
      font-family: $primary-font;
      font-size: rem(20);
      @media (max-width: $md) {
        font-size: rem(14);
      }
    }

    svg {
      border-radius: 50%;
      border: 2px solid $color-4;
      padding: 8px;
      transition: 0.3s ease;
      width: 50px;
      height: 50px;
    }
  }
  &__map {
    width: 50%;
    @media (max-width: $lg) {
      width: 100%;
      aspect-ratio: 2/1;
    }
  }
}
