.four-cols {
  position: relative;
  padding-block: 40px;
  margin-top: rem(-320);
  position: relative;
  @media (max-width: $md) {
    margin-top: 50px;
    padding-block: 0;
  }
  h2 {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    display: block;
    width: rem(1);
    height: rem(1);
    overflow: hidden;
  }
  &__container {
    flex-direction: column;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: rem(50);
    align-items: stretch;
    flex-wrap: wrap;
    @media (max-width: $md) {
      gap: rem(20);
    }

    @media (max-width: $sm) {
      gap: rem(10);
      flex-wrap: nowrap;
      flex-direction: column;
    }
  }
  &__col {
    flex-direction: column;
    gap: 15px;
    flex: 1;
    text-align: center;
    background-color: $color-1;
    padding: rem(20);
    border-radius: 5px;

    svg {
      border-radius: 50%;
      transition: 0.3s;
    }

    &:hover svg path {
      fill: $color-1;
      transition: 0.3s;
    }
    &:hover svg {
      background-color: $color-4;
    }
    h3 {
      color: $color-4;
      font-family: $primary-font;
      font-size: rem(16);
      margin-top: rem(20);
    }
  }
  h2 {
    color: $color-4;
    margin-bottom: 30px;
  }
}
