.short {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: rem(900);
  margin-inline: auto;
  gap: rem(30);
  @media (max-width: $md) {
    flex-direction: column-reverse;
    text-align: center;
    padding-inline: rem(15);
    margin-top: rem(30);
  }
  &__text,
  img {
    flex: 1;
  }
}
