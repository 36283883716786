.about-hero {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  @media (max-width: $lg) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 50px;
    width: 60%;
    @media (max-width: $lg) {
      width: 100%;
    }
  }
  &__img {
    width: 40%;
    display: flex;
    justify-content: center;

    img {
      max-width: 600px;
      width: 100%;
      border-radius: 0 40px 0 40px;
    }
    @media (max-width: $lg) {
      width: 100%;
    }
  }
}
