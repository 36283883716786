.contact-section {
  display: flex;
  justify-content: space-between;
  gap: 100px;

  padding-top: 100px;
  @media (max-width: $xl) {
    flex-direction: column;
    align-items: center;
    gap: 100px;
  }
  @media (max-width: $lg) {
    padding-top: 30px;
  }
  &__left {
    @media (max-width: $xl) {
      width: 100%;
    }
  }
  &__right {
    display: flex;
    text-align: center;
    gap: 50px;
    margin-block: 100px;
    margin-inline: auto;
    @media (max-width: $lg) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    h2 {
      margin-top: 10px;
      margin-bottom: 30px;
      color: $color-1;
    }
    &__adress {
      width: 50%;
      @media (max-width: $lg) {
        width: 100%;
      }
      p {
        font-weight: 500;
      }
    }
    &__contact-info {
      border-left: 1px solid $color-1;
      padding-bottom: 100px;
      padding-inline: 50px;
      width: 50%;
      @media (max-width: $xl) {
        border: none;
      }
      @media (max-width: $lg) {
        padding: 0;
      }
    }
  }
  &__element {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    @media (max-width: $md) {
      margin-bottom: 10px;
    }
    svg {
      min-width: 30px;
      @media (max-width: $md) {
        display: none;
      }
    }
    a {
      font-size: rem(20);
      color: $color-1;
      font-weight: 500;
      @media (max-width: $md) {
        font-size: rem(15);
      }
    }
  }
  &__map {
    width: 100%;
    aspect-ratio: 4/1;
    @media (max-width: $md) {
      aspect-ratio: 1/1;
    }
  }
}
